.autocomplete-dropdown-container {
  border-radius: 0;

  .suggestion-item,
  .suggestion-item--active,
  .suggestion-loading {
    padding: $padding-base-vertical $padding-base-horizontal;
  }
}

.ReactTable {
  .-pagination {
    .-pageJump {
      margin: 0 3px;
    }

    input {
      text-align: center;
    }
  }

  .rt-tbody {
    padding: 0px 5px;
  }

  .rt-thead.-filters {
    border: 0;
  }

  .rt-td {
    padding: 7px 7px;
  }

  .-totalPages {
    padding-left: 3px;
  }
}

.card-calendar {
  .content {
    padding: 0px;
    height: 500px;
  }

  .rbc-toolbar {
    flex-wrap: wrap;

    @media (max-width: $screen-md) {
      flex-direction: column-reverse;
    }

    button {
      padding: 4px 8px;
    }

    .rbc-toolbar-label {
      text-align: right;
      font-size: 26px;
      padding: 5px 0;
      margin-bottom: 15px;

      @media (max-width: $screen-md) {
        text-align: center;
      }
    }

    .rbc-btn-group {
      margin: 0 auto 15px auto;
    }
  }

  .rbc-day-bg {
    cursor: pointer;
  }

  .rbc-row {
    cursor: pointer;
  }

  .rbc-month-view {
    height: 60%;
  }

  .clearBoth {
    clear: both;
  }

  .clearBoth::before,
  .clearBoth::after {
    flex-basis: 0;
    order: 1;
  }

  .overflowAuto,
  .card-calendar {
    overflow: auto;
  }

  .rbc-off-range-bg {
    background: #f9f9f9;
  }

  .rbc-today {
    background: #ffc10755;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 90%;
  }
}

.sweet-alert {
  h2 {
    font-size: 26px;
  }

  button {
    margin-bottom: 15px;
  }
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  background-color: #f5f5f5;
}

.tracker-page,
.lock-page {
  > .content {
    padding-top: 14vh;
  }
}

.tracker-page {
  > .content {
    padding-top: 4vh;
  }
}

.tracker-page {
  .card {
    box-shadow: 0 25px 30px -13px rgba(40, 40, 40, 0.4);
    border-radius: 0;
    padding-top: $padding-default-horizontal;
    padding-bottom: $padding-default-horizontal;

    @include transform-translate-y(0);
    @extend .animation-transition-general;

    &.card-hidden {
      opacity: 0;
      @include transform-translate-y(-60px);
    }

    .btn-wd {
      min-width: 180px;
    }
  }

  .vertical-timeline::before {
    background: rgba(0, 0, 0, 0.1);
  }

  .vertical-timeline-element-content {
    border-radius: 0 !important;
  }
}

.vertical-timeline-element-title {
  text-transform: capitalize;
}

.ReactTable .-pagination {
  input {
    max-width: 60px;
  }

  select {
    max-width: 85px;
  }
}

.sidebar-mini .sidebar {
  z-index: 1000 !important;
  .logo {
    min-height: 90px;
  }
  .sidebar-wrapper {
    height: calc(100% - 90px);
  }
}

.sidebar {
  .logo {
    min-height: 120px;
  }

  .user {
    margin-top: 0;
  }

  .sidebar-wrapper {
    height: calc(100% - 120px);
  }

  .logo-img img {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .nav p {
    line-height: 34px;
  }
}

.jumbotron {
  background: rgb(238, 238, 238);
  background: linear-gradient(90deg, rgba(238, 238, 238, 1) 0%, rgba(245, 245, 245, 1) 100%);
}

.card .content .tab-pane .table-responsive {
  padding: 0;
}

.card .content .tab-pane .table > thead > tr > th {
  color: #444;
}

.buttons-with-right-margin {
  .btn {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  background: #444;
  color: #fff;
}

.nav-tabs > li > a {
  color: #444;
}

.mb-10 {
  margin-bottom: 10px;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  height: 39px;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 39px;
  padding: 10px 12px;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(39px - 1px * 2);
    padding: 10px 12px;
    color: #495057;
    content: "Przeglądaj";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
  }
}

.modal-backdrop:nth-child(1) {
  z-index: 1050;
}

.trash {
  text-decoration: line-through;
  color: #dc3545;
}

.trash-button {
  button {
    text-decoration: line-through;
    color: #dc3545;
  }
}

.react-select-intable .react-select__control .react-select__indicators {
  width: 40px;
}

.react-select__menu {
  z-index: 999;
}

.Select-value-label,
.Select-option {
  white-space: pre-wrap;
}

.table-zones .form-control {
  min-width: 100px;
}

#tabs-company .table-responsive table {
  margin-bottom: 250px;
}

.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-d {
  overflow: visible !important;
}

.gm-style .gm-style-iw-t::after {
  z-index: -1;
}

.gm-style .gm-iw {
  padding: 5px 10px 20px 5px;
}

.mini-input-right {
  float: right;
  width: 50px;
  height: 28px;
  font-size: 11px;
  border-radius: 0;
  padding: 4px 6px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.notifications-tr {
  max-width: 80vw !important;
}

.sweet-alert {
  > * {
    max-width: 80vw !important;
    margin: 10px auto !important;
    display: block !important;
  }
}
